import Vue from 'vue';
import axios from 'axios';
import config from '@/api/config';

const install = function(){
    const instance = axios.create({
        baseURL:config.domain,
        timeout:300000,
        withCredentials:true
    });


    //请求拦截处理
    instance.interceptors.request.use((config) => {
        if(config.debug){
            console.log('reqeust:' , config);
        }

        // if(query.indexOf(config.url) != -1){
        //     Vue.prototype.$message({
        //         message:'请求繁忙，请稍后再试',
        //         type:'warning'
        //     });
        //     throw '请求繁忙';
        // }
        // query.push(config.url);
        return config;
    }, error => {
        return Promise.reject(error);
    });

    //响应拦截处理
    instance.interceptors.response.use(response => {
        if(config.debug){
            console.log('response:', response);
        }
        return checkCode(response.data);
    },  error => {
        Vue.prototype.$message({
            message:error.response.data.msg || error.message,
            type:'error'
        });
        return Promise.reject(error.response.data || error.message);
    });

    function checkCode(resp){
        if(resp.code === 0){
            return resp;
        }else if(resp.code >= 300 && resp.code < 400){
            let url = resp.redirect;
            if(resp.msg){
                url = resp.baseUrl + '/Notice?msg='+resp.msg;
                if(resp.redirect){
                    url += '&redirect='+resp.redirect;
                }
            }
            window.location = url;
        }else{
            Vue.prototype.$message({
                message:resp.msg,
                type:resp.type || 'warning'
            });
            return Promise.reject(resp);
        }
    }

    return instance
}

export default {
    install
}