<template>
    <div class="home-container" :class="[platform=='mobile'?'mobile':'pc']">
        <div class="port-container" v-show="isShow">
            <div class="mask unwanted"></div>
            <div class="port-wrapper unwanted">
                <Port :isShow="isShow" @submit="isShow = false" />
            </div>
        </div>
        <div class="nav-container" :class="{ active: navBarFixed , activeMobile:scrollT}" id="navBar">
            <div class="nav-bar">
                <div class="hiddenele">
                    <img src="https://qiniu.linfun.cn//neteast.cn/logo.png" alt="logo" />
                    <span>东际网丰</span>
                </div>
                <ul class="nav">
                    <li class="unwanted" @click="goTo('#company', 0)">公司介绍</li>
                    <li class="unwanted" @click="goTo('#service', -120)">产品服务</li>
                    <li class="unwanted" @click="goTo('#partner', -20)">合作伙伴</li>
                    <li class="unwanted" @click="goTo('#contact', 0)">联系我们</li>
                    <li class="unwanted lastEle" @click="isShow = true">
                        <i class="el-icon-s-promotion unwanted"></i>
                        合作洽谈
                    </li>
                    <router-link to="/port" class="hide">
                        <li class="lastEle">合作洽谈</li>
                    </router-link>
                </ul>
            </div>
        </div>
        <div class="container" id="company">
            <div class="upper" ref="upper" :style="{transition:transition,transform:upperTranslateY}" @touchstart="touchMove" @touchmove="touchMove">
                <div class="banner-wrapper">
                    <div class="banner-content">
                        <div class="com-lname unwanted">
                            <span class="com-fname">东际网丰</span>
                            <span>（福建）科技有限公司</span>
                        </div>
                        <span class="slogan"
                            >科技创新<span class="comma">,</span>共享卓越</span
                        >
                        <p class="profile">
                            东际网丰（福建）科技有限公司于2019年初创，主营业务涵盖软件设计、开发、运维。我们还期望未来能实现虚拟游戏世界与万物互联的探索。
                        </p>
                    </div>
                    <div class="appendix unwanted">
                        <img src="https://qiniu.linfun.cn//neteast.cn/logo.png" alt="logo" />
                        <img
                            src="https://qiniu.linfun.cn//neteast.cn/neteast.png"
                            alt="neteast"
                            class="com-name"
                        />
                        <div class="sloganEn">Code your idea</div>
                        <div class="spot-matrix1" />
                    </div>
                    <div class="spot-matrix2 unwanted" />
                    <div class="portrait-wrapper hide">
                        <!-- <img src="https://qiniu.linfun.cn//neteast.cn/Paint/people.png" class="hide people"> -->
                        <img
                            ref="portrait"
                            class="portrait unwanted"
                            src="https://qiniu.linfun.cn//neteast.cn/portrait.png"
                            alt="portrait"
                        />
                        <img
                            ref="planet1"
                            class="planet1 unwanted"
                            src="https://qiniu.linfun.cn//neteast.cn/planet1.png"
                            alt="planet1"
                        />
                        <img
                            ref="planet2"
                            class="planet2 unwanted"
                            src="https://qiniu.linfun.cn//neteast.cn/planet2.png"
                            alt="planet2"
                        />
                        <img
                            ref="cloud1"
                            class="cloud1 unwanted"
                            src="https://qiniu.linfun.cn//neteast.cn/cloud1.png"
                            alt="cloud1"
                        />
                        <img
                            ref="cloud2"
                            class="cloud2 unwanted"
                            src="https://qiniu.linfun.cn//neteast.cn/cloud2.png"
                            alt="cloud2"
                        />
                        <img
                            ref="cloud3"
                            class="cloud3 unwanted"
                            src="https://qiniu.linfun.cn//neteast.cn/cloud3.png"
                            alt="cloud3"
                        />
                    </div>
                    <div class="lottie-box unwanted" id="lottie_box">
                        <div class="earth unwanted"></div>
                    </div>
                </div>
                <div class="service-wrapper" id="service">
                    <img
                        src="https://qiniu.linfun.cn//neteast.cn/service.png"
                        alt="service"
                        class="service-title unwanted"
                    />
                    <h2 class="hide">产品服务</h2>
                    <h3>SERVICE ITEAMS</h3>
                    <div class="card-wrapper">
                        <div class="card card1">
                            <img src="https://qiniu.linfun.cn//neteast.cn/icon1.png" class="unwanted" alt="icon1" />
                            <img src="https://qiniu.linfun.cn//neteast.cn/Paint/information.png" class="hide">
                            <div class="info">
                                <span>信息系统化</span>
                                <div class="line unwanted" />
                                <p>信息化系统开发与集成</p>
                            </div>
                        </div>
                        <div class="card card2">
                            <img src="https://qiniu.linfun.cn//neteast.cn/icon2.png" class="unwanted" alt="icon2" />
                            <img src="https://qiniu.linfun.cn//neteast.cn/Paint/development.png" class="hide">
                            <div class="info">
                                <span>设计与开发</span>
                                <div class="line" />
                                <p>web 应用设计与开发（网站、公众号、小程序）</p>
                            </div>
                        </div>
                        <div class="card card3">
                            <img src="https://qiniu.linfun.cn//neteast.cn/icon3.png" class="unwanted" alt="icon3" />
                            <img src="https://qiniu.linfun.cn//neteast.cn/Paint/safeguard.png" class="hide">
                            <div class="info">
                                <span>维护与托管</span>
                                <div class="line" />
                                <p>官网、公众号、小程序托管服务</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="partner-wrapper" id="partner" @touchstart="slideStart" @touchmove="slide('upper',$event)" @touchend="slideEnd('upper',$event)">
                    <h2>合作伙伴</h2>
                    <h3>SERVICE ITEAMS</h3>
                    <div class="item-wrapper">
                        <div class="item blue">
                            <div class="brand">
                                <div class="img-wrapper">
                                    <img
                                        src="https://qiniu.linfun.cn//neteast.cn/jiaojian.png"
                                        alt="jiaojian"
                                        class="unwanted"
                                    />
                                    <img src="https://qiniu.linfun.cn//neteast.cn/Logo/JJJT.png" class="hide">
                                </div>
                            </div>
                            <div class="info2">
                                <span>福州交建集团（信息化管控平台）</span>
                                <span class="p">通过bim实现整体工程进度与工地信息化管理</span>
                                <div class="check unwanted" @click="showPop">立即查看</div>
                            </div>
                        </div>
                        <div class="item green reverse">
                            <div class="brand">
                                <div class="img-wrapper">
                                    <img
                                        src="https://qiniu.linfun.cn//neteast.cn/manhuacheng.png"
                                        alt="manhuacheng"
                                        class="unwanted"
                                    />
                                    <img src="https://qiniu.linfun.cn//neteast.cn/Logo/top.png" class="hide">
                                </div>
                            </div>
                            <div class="info2">
                                <span >东联传媒（Top漫画城）</span>
                                <span class="p">
                                    定制化网上书店，涵盖用户、库存、订单、购物车管理，定制订书与询量功能
                                </span>
                                <div class="check unwanted" @click="showPop">立即查看</div>
                            </div>
                        </div>
                        <div class="item orange">
                            <div class="brand">
                                <div class="img-wrapper">
                                    <img src="https://qiniu.linfun.cn//neteast.cn/funeng.png" class="unwanted" alt="funeng" />
                                    <img src="https://qiniu.linfun.cn//neteast.cn/Logo/fnxl.png" class="hide">
                                </div>
                            </div>
                            <div class="info2">
                                <span>福能心理（心理评测系统）</span>
                                <span class="p">
                                    提供个人小程序端，实时跟进个人及团体心理评测相关数据，提供专业分析视图
                                </span>
                                <div class="check unwanted" @click="showPop">立即查看</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact-wrapper" id="contact" ref="contact" :style="{transition:transition,transform:contactTranslateY}">
                <div class="spot-matrix3" />
                <div class="info">
                    <h2 class="title h2">联系我们</h2>
                    <h3 class="title">CONTACT US</h3>
                    <div class="toTop hide" @touchstart="backTop">
                        <img src="https://qiniu.linfun.cn//neteast.cn/Icon/backtop.png" class="hide">
                    </div>
                    <div class="back-origin" @touchstart="backOrigin">
                        <img src="https://qiniu.linfun.cn//neteast.cn/Icon/Currentlocation.png" class="hide">
                    </div>
                    <div class="detail-wrapper" @touchstart="slideStart" @touchmove="slide('contact',$event)" @touchend="slideEnd('contact',$event)">
                        <div class="code">
                            <div class="code-img">
                                <img
                                    src="https://qiniu.linfun.cn//neteast.cn/code.png"
                                    alt="code"
                                    class="codeImg"
                                />
                            </div>
                            <span class="unwanted">技术运营中心</span>
                            <div class="hide">扫码访问 技术运营中心</div>
                        </div>
                        <div class="detail">
                            <div class="address info">
                                <img src="https://qiniu.linfun.cn//neteast.cn/Icon/location.png" class="hide icon">
                                柳河路36号黄金集团3号楼2层
                            </div>
                            <div class="telephone info">
                                <img src="https://qiniu.linfun.cn//neteast.cn/Icon/mobile.png" class="hide icon">
                                <img src="https://qiniu.linfun.cn//neteast.cn/tele.png" class="unwanted" alt="tele" />
                                0591-62116068
                            </div>
                            <div class="mail info">
                                <img src="https://qiniu.linfun.cn//neteast.cn/Icon/mail.png" class="hide icon">
                                <img src="https://qiniu.linfun.cn//neteast.cn/mail.png" class="unwanted" alt="mail" />
                                linfuchuan@live.cn
                            </div>
                            <span class="unwanted">公司地址：</span>
                            <div class="address unwanted">
                                福州市鼓楼区南街街道柳河路36号黄金集团3号楼2层
                            </div>
                            <div class="link mail-link">
                                <img src="https://qiniu.linfun.cn//neteast.cn/Icon/email.png" class="hide icon-btn main-btn">
                                <a href="mailto:linfuchuan@live.cn"></a>
                            </div>
                            <div class="link">
                                <img src="https://qiniu.linfun.cn//neteast.cn/Icon/phone.png" class="hide icon-btn phone-btn"> 
                                <a href="tel:0591-62116068"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map-wrapper">
                    <div class="map"><Map ref="map" /></div>
                    <div class="mask"></div>
                </div>
                <div class="footer hide" id="footer" ref="footer">
                    <a target="_blank" href="http://beian.miit.gov.cn">闽ICP备2021007772号</a>
                </div>
            </div>
        </div>
    </div>
    <div :class="[platform=='mobile'?'mobile':'pc','footer']">
        <div class="unwanted">
            <a  target="_blank" href="http://beian.miit.gov.cn"
                >闽ICP备2021007772号</a
            >
        </div>
    </div>
</template>

<script>
import Map from "@/components/Map/Map.vue";
import Port from "@/components/Port/Port.vue";
import platform from "@/api/platform";
import lottie from 'lottie-web';
import graphJSON from '@/assets/json/data.json'

export default {
    name: "Home",
    components: {
        Map,
        Port,
    },
    mounted() {
        this.platform=platform
        this.screenHeight=document.documentElement.clientHeight
        if (this.platform=='mobile'){
            this.$refs.upper.style.height=this.$refs.contact.style.height=this.screenHeight+'px'
            document.body.style.overflow='hidden'
        }else{
            this.lottie = lottie.loadAnimation({
                container: document.getElementById('lottie_box'),
                renderer: 'svg',
                loop: true,
                animationData: graphJSON
            })
        }
        window.addEventListener("scroll", this.handleScroll);
        console.log('导入的json文件',this.graph);
    },
    data() {
        return {
            navBarFixed: false,
            // scrollIntoViewOptions: { behavior: "smooth", block: "center" },
            isShow: false,
            timer: null,
            platform:'',
            screenHeight:0,
            startPositionY:0,
            transition:'',
            upperTranslateY:'translateY(0)',
            contactTranslateY:'translateY(100%)',
            scrollT:0,
            lottie: {},
        };
    },
    methods: {
        // isMobile() {
        //     let flag = navigator.userAgent.match(
        //         /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        //     );
        //     return flag;
        // },
        showPop() {
            this.$alert('即将上线，敬请期待~', '提示', {
            confirmButtonText: '确定'
            });
        },
        handleScroll() {
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            var offsetTop = document.querySelector("#navBar").offsetTop;
            if (scrollTop > offsetTop) {
                this.navBarFixed = true;
            } else {
                this.navBarFixed = false;
            }
        },
        resetTimer() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
        },
        scrollTo(targetScrollT) {
            console.log(targetScrollT);
            this.resetTimer();
            let self = this;
            let maxScrollT = document.body.scrollHeight - window.innerHeight;

            if (targetScrollT > maxScrollT) {
                targetScrollT = maxScrollT;
            }
            this.timer = setInterval(() => {
                let scrollT =
                    document.body.scrollTop ||
                    document.documentElement.scrollTop;
                let step = Math.floor((targetScrollT - scrollT) / 10);
                document.documentElement.scrollTop = document.body.scrollTop =
                    step + scrollT;
                if (Math.abs(targetScrollT - scrollT) <= 20) {
                    if (scrollT <= 20) {
                        document.documentElement.scrollTop = document.body.scrollTop = 0;
                    }
                    clearInterval(self.timer);
                }
            }, 10);
        },
        goTo(target, offset) {
            var targetScrollT =
                document.querySelector(target).offsetTop + offset;
            this.scrollTo(targetScrollT);
        },
        changeTo(target) {
            const returnEle = document.querySelector(target);
            if (returnEle) {
                returnEle.scrollIntoView();
            }
        },
        cloudMove(e) {
            let cx=e.clientX
            let cy=e.clientY
            this.$refs.planet2.style.transform=`translateX(${cx/1000}px) translateY(${cy/1000}px)`
            this.$refs.cloud3.style.transform=`translateX(${cx/700}px) translateY(${cy/700}px)`
            this.$refs.portrait.style.transform=`translateX(${cx/500}px) translateY(${cy/500}px)`
            this.$refs.planet1.style.transform=`translateX(${cx/400}px) translateY(${cy/400}px)`
            this.$refs.cloud1.style.transform=`translateX(${cx/200}px) translateY(${cy/200}px)`
            this.$refs.cloud2.style.transform=`translateX(${cx/200}px) translateY(${cy/200}px)`
        },
        backTop(){
            this.showUpper()
            // this.scrollTo(0)
        },
        showContact(){
            this.transition='transform 0.5s ease'
            this.upperTranslateY=`translateY(-${this.screenHeight*1.05}px)`
            this.contactTranslateY= `translateY(0)`
        },
        showUpper(){
            this.transition='transform 0.5s ease'
            this.upperTranslateY=`translateY(0)`
            this.contactTranslateY='translateY(105%)'
        },
        slideStart(e){
            this.startPositionY=e.changedTouches[0].clientY
        },
        slide(type,e){
            let step = e.changedTouches[0].clientY-this.startPositionY
            if(type=="upper"){
                if(this.$refs.upper.scrollTop>=this.$refs.upper.scrollHeight-this.$refs.upper.clientHeight-20){
                    if(step < 0){
                        if(step < -200){
                            this.showContact()
                            return
                        }
                        this.transition=''
                        this.upperTranslateY=`translateY(${step}px)`
                        this.contactTranslateY=`translateY(${step+this.screenHeight*1.05}px)`
                    }
                }
            }else if(type=='contact'){
                e.preventDefault()
                if(step > 0){
                    if(step > 200){
                        this.showUpper()
                        return
                    }
                    this.transition=''
                    this.upperTranslateY=`translateY(${step-this.screenHeight*1.05}px)`
                    this.contactTranslateY=`translateY(${step}px)`
                }
            }
            
        },
        slideEnd(type,e){
            let length=e.changedTouches[0].clientY-this.startPositionY
            if(type=='upper'){
                if(length > -200){
                    this.showUpper()
                    return
                }
            }else if(type == 'contact'){
                if(length < 200){
                    this.showContact()
                    // let scrollHeight=document.documentElement.scrollHeight
                    // this.scrollTo(scrollHeight-this.screenHeight)
                    return
                }
            }
        },
        backOrigin(){
            this.$refs.map.createMap()
        },
        touchMove(){
            this.scrollT=this.$refs.upper.scrollTop
        }
    }
};
</script>

<style lang="scss" scoped>
    @import 'Home';
</style>
