<template>
    <div class="port-box" id="portBox" ref="portBox">
        <div class="closebtn-mask" ref="header">
            <div class="brand hide">
                <img src="https://qiniu.linfun.cn//neteast.cn/logo.png" alt="logo">
                <span>东际网丰</span>
            </div>
            <router-link to='/'>
                <div class="closebtn">
                    <i class="el-icon-close"></i>    
                </div>
            </router-link>
        </div>
        <div class="port-header">
            合作洽谈 <span class="hide">BUSINESS COOPERATION</span>
        </div>
        <div class="line"></div>
        <div class="form">
            <el-form class="form-mobile hide" :model="ruleForm" :rules="rules" ref="ruleForm">
                    <el-form-item prop="username">
                        <el-input
                            type="text"
                            class="username"
                            maxlength="30"
                            v-model="ruleForm.username"
                            placeholder="请输入您的姓名"
                            @blur="blur"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="type">
                        <el-select
                            class="item-select"
                            v-model="ruleForm.type"
                            placeholder="请选择项目类型"
                            @blur="blur"
                        >
                            <el-option
                                v-for="(option, index) in options"
                                :key="index"
                                :label="option"
                                :value="index"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="mobile">
                        <el-input
                            class="phone"
                            type="text"
                            maxlength="11"
                            v-model.number="ruleForm.mobile"
                            placeholder="请输入您的联系方式"
                            @blur="blur"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="content">
                        <el-input
                            class="content"
                            type="textarea"
                            placeholder="请输入您对所要开发/托管项目的需求"
                            v-model="ruleForm.content"
                            maxlength="512"
                            rows="8"
                            show-word-limit
                            resize="none"
                            @blur="blur"
                        ></el-input>
                    </el-form-item>
                    <div class="tip">备注：我们限制5分钟内仅能提交一次，感谢理解</div>
                    <el-form-item>
                        <el-button class="submit" @click="submitForm('ruleForm')"
                            >提交/SEND</el-button
                        >
                    </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { ElLoading } from 'element-plus';
import axios from "axios";
import config from '@/api/config';
import headerImg from '@/assets/Paint/nav-bar.png'
export default {
    name: "Port",
    props: ["isShow"],
    data() {
        var checkMobile = (rule, value, callback) => {
            if (!value.toString().match(/^1[0-9]{10}$/)) {
                callback(new Error("请输入有效的手机号码"));
            }
            callback();
        };
        // localStorage.setItem('nosend',false);
        return {
            options: ["定制开发", "公众号/小程序", "托管服务"],
            ruleForm: {
                username: "",
                type: "",
                mobile: "",
                content: "",
                imgUrl:headerImg
            },
            rules: {
                username: [
                    { required: true, message: "姓名不能为空", trigger: "blur" }
                ],
                type: [
                    { required: true, message: "请选择类型", trigger:"blur"}
                ],
                mobile: [
                    { required: true, message: "手机号码不能为空" , trigger: "blur"  },
                    { type: "number", message: "手机号码必须为数字", trigger: "blur"  },
                    { validator: checkMobile, trigger: "blur"  }
                ],
                content: [
                    {
                        required: true,
                        message: "内容不能为空",
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    mounted(){
        this.$refs.portBox.style.minHeight=window.screen.availHeight+'px'
    },
    methods: {
        submitForm(formName) {
            let self = this;
            this.$refs[formName].validate().then(valid => {
                if (valid) {
                    self.submit();
                    self.ruleFormReset();
                } else {
                    return false;
                }
            });
        },
        getNowTime(){
            return  parseInt((new Date()).getTime() / 1000)
        },
        submit() {
            let expire = localStorage.getItem('nosend')
            if (expire && expire > this.getNowTime()) {
                this.pop()
                return
            }
            let loadingInstance = ElLoading.service({
                lock: true,
                target:'#portBox',
                text: '数据提交中~',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            axios
                .post(config.domain+"/Home/Contactus/", this.ruleForm,{timeout:300000})
                .then(
                    resp => {
                        ElMessage({
                            showClose: true,
                            message: resp.data.msg,
                            type: "success"
                        });
                        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                            loadingInstance.close();
                        });
                        this.$emit("submit", resp.data);
                    },
                    error => {
                        ElMessage({
                            showClose: true,
                            message: error.data.msg,
                            type: "error"
                        });
                        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                            loadingInstance.close();
                        });
                    }
                );
            this.sendLimit()
        },
        sendLimit () {
            localStorage.setItem('nosend', this.getNowTime() + 300)
        },
        pop(){
            alert('我们限制5分钟内仅能提交一次，感谢理解');
        },
        ruleFormReset(){
            this.ruleForm.username='';
            this.ruleForm.type='';
            this.ruleForm.mobile='';
            this.ruleForm.content=''
        },
        // close() {
        //     this.$emit("submit");
        // },
        blur(){
            this.$refs.header.scrollIntoView()
        }
    }
};
</script>

<style lang="scss" scoped>
.unwanted{
    display: none;
}
.port-box{
    background-color: #fff;
    padding-bottom: 64px;
    .closebtn-mask{
        height: 64px;
        width: 100%;
        background-image: url('https://qiniu.linfun.cn//neteast.cn/Paint/nav-bar.png');
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: center;
        padding: 0 24px;
        justify-content: space-between;
        .brand{
            display: flex;
            width: 70%;
            align-items: center;
            img {
                width: 40px;
                height: 40px;
                margin-right: 12px;
            }
            span {
                font-size: 24px;
                color: #fff;
            }
        }
        .closebtn{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            i{
                font-size: 14px;
            }
        }
    }
    .port-header{
        padding: 0 24px;
        font-size: 22px;
        @media screen and (max-width:350px){
            font-size: 20px;
        }
        margin: 22px 0;
        span{
            font-size: 16px;
            @media screen and (max-width:350px){
                font-size: 12px;
            }
            color: #C4C4C4;
        }
    }
    .form{
        padding: 0 24px;
        .tip{
            font-size: 12px;
            color: #9EA7B4;
            margin-top: -8px;
            margin-bottom: 33px;
        }
        .submit{
            width: 100%;
            height: 48px;
            border-radius: 24px;
            background: linear-gradient(169.52deg, #F479E8 11.05%, #2984FA 83.85%);
            box-shadow: 0px 2px 10px rgba(145, 129, 253, 0.2);
            color: #fff;
            font-size: 14px;
        }
    }
}
</style>
