<template>
    <div id="app">
        <router-link to="/" />
        <router-view />
    </div>
</template>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#app {
    
}
</style>
