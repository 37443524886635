let data;
let hostname=window.location.hostname
console.log(process.env.NODE_ENV);
if(process.env.NODE_ENV == 'development'){
    data = {
        debug:true,
        imageStorePath:'file',
        domain:'http://neteast.test'
    }
}else{
    data = {
        debug:false,
        imageStorePath:'file',
        domain:`https://${hostname}`
    }
}
export default data;
