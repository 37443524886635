<template>
    <div class="port-box" id="portBox">
        <div class="closebtn-mask">
            <div class="closebtn" @click="close">
                <i class="el-icon-close"></i>
            </div>
        </div>
        <div class="port-header">
            合作洽谈
        </div>
        <div class="line"></div>
        <div class="form">
            <el-form
                class="elform"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
            >
                <div class="formEle">
                    <el-form-item prop="username">
                        <el-input
                            prefix-icon="el-icon-user"
                            type="text"
                            class="username"
                            maxlength="30"
                            v-model="ruleForm.username"
                            placeholder="请输入您的姓名"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="type">
                        <el-select
                            class="item-select"
                            v-model="ruleForm.type"
                            placeholder="请选择项目类型"
                        >
                            <el-option
                                v-for="(option, index) in options"
                                :key="index"
                                :label="option"
                                :value="index"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item prop="mobile">
                    <el-input
                        class="phone"
                        prefix-icon="el-icon-mobile-phone"
                        type="text"
                        maxlength="11"
                        v-model.number="ruleForm.mobile"
                        placeholder="请输入您的联系方式"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="content">
                    <el-input
                        class="content"
                        type="textarea"
                        placeholder="请输入您对所要开发/托管项目的需求"
                        v-model="ruleForm.content"
                        maxlength="512"
                        rows="8"
                        show-word-limit
                        resize="none"
                    ></el-input>
                </el-form-item>
                <div class="tip">
                    备注：我们限制5分钟内仅能提交一次，感谢理解
                </div>
                <el-form-item>
                    <el-button class="submit" @click="submitForm('ruleForm')"
                        >提交/SEND</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { ElLoading } from "element-plus";
import axios from "axios";
import config from "@/api/config";
export default {
    name: "Port",
    props: ["isShow"],
    data() {
        var checkMobile = (rule, value, callback) => {
            if (!value.toString().match(/^1[0-9]{10}$/)) {
                callback(new Error("请输入有效的手机号码"));
            }
            callback();
        };
        // localStorage.setItem('nosend',false);
        return {
            options: ["定制开发", "公众号/小程序", "托管服务"],
            ruleForm: {
                username: "",
                type: "",
                mobile: "",
                content: ""
            },
            rules: {
                username: [
                    { required: true, message: "姓名不能为空", trigger: "blur" }
                ],
                type: [
                    { required: true, message: "请选择类型", trigger: "blur" }
                ],
                mobile: [
                    {
                        required: true,
                        message: "手机号码不能为空",
                        trigger: "blur"
                    },
                    {
                        type: "number",
                        message: "手机号码必须为数字",
                        trigger: "blur"
                    },
                    { validator: checkMobile, trigger: "blur" }
                ],
                content: [
                    {
                        required: true,
                        message: "内容不能为空",
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    methods: {
        submitForm(formName) {
            let self = this;
            this.$refs[formName].validate().then(valid => {
                if (valid) {
                    self.submit();
                    self.ruleFormReset();
                } else {
                    return false;
                }
            });
        },
        getNowTime() {
            return parseInt(new Date().getTime() / 1000);
        },
        submit() {
            let expire = localStorage.getItem("nosend");
            if (expire && expire > this.getNowTime()) {
                this.pop();
                return;
            }
            let loadingInstance = ElLoading.service({
                lock: true,
                target: "#portBox",
                text: "数据提交中~",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.7)"
            });
            axios
                .post(config.domain + "/Home/Contactus/", this.ruleForm, {
                    timeout: 300000
                })
                .then(
                    resp => {
                        ElMessage({
                            showClose: true,
                            message: resp.data.msg,
                            type: "success"
                        });
                        this.$nextTick(() => {
                            // 以服务的方式调用的 Loading 需要异步关闭
                            loadingInstance.close();
                        });
                        this.$emit("submit", resp.data);
                    },
                    error => {
                        ElMessage({
                            showClose: true,
                            message: error.data.msg,
                            type: "error"
                        });
                        this.$nextTick(() => {
                            // 以服务的方式调用的 Loading 需要异步关闭
                            loadingInstance.close();
                        });
                    }
                );
            this.sendLimit();
        },
        sendLimit() {
            localStorage.setItem("nosend", this.getNowTime() + 300);
        },
        pop() {
            this.$alert("我们限制5分钟内仅能提交一次，感谢理解", "友情提醒", {
                confirmButtonText: "确定"
            });
        },
        ruleFormReset() {
            this.ruleForm.username = "";
            this.ruleForm.type = "";
            this.ruleForm.mobile = "";
            this.ruleForm.content = "";
        },
        close() {
            this.$emit("submit");
        }
    }
};
</script>

<style lang="scss" scoped>
.port-box {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: #ffffff;
    position: relative;
    .closebtn-mask {
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: default;
        width: 70px;
        height: 70px;
        border-radius: 0 10px 0 0;
        overflow: hidden;
        .closebtn {
            position: absolute;
            top: -30px;
            right: -15px;
            width: 80px;
            height: 80px;
            background-color: #c4c4c4;
            border-radius: 0 0 0 80px;
            box-shadow: inset -2px -22px 10px rgba(157, 160, 164, 0.25);
            color: #ffffff;
            transition: background-color 0.2s ease;
            &:hover {
                background-color: #f44583;
                box-shadow: inset -2px -22px 10px rgba(244, 69, 131, 0.25);
            }
            i {
                position: absolute;
                left: 36px;
                top: 36px;
                font-size: 20px;
            }
        }
    }
    .port-header {
        height: 70px;
        text-align: center;
        line-height: 70px;
        color: #555555;
        font-size: 24px;
    }
    .line {
        width: 100%;
        height: 2px;
        margin-bottom: 50px;
        background: linear-gradient(90deg, #1589f4 44.43%, #f13d78 56.32%);
    }
    .form {
        padding: 0 100px;
        .elform {
            width: 536px;
            margin: 0 auto;
            .username {
                width: 205px;
                margin-right: 19px;
            }
            .item-select {
                width: 312px;
            }
            .submit {
                display: block;
                width: 200px;
                border-radius: 30px;
                margin: 20px auto 40px auto;
                color: #ffffff;
                background: linear-gradient(
                    -87.32deg,
                    #06d2ff 0%,
                    #9206ff 100%
                );
            }
        }
        .tip {
            color: #d1d1d1;
            font-size: 12px;
        }
        .formEle {
            display: flex;
            & > div {
                vertical-align: middle;
            }
        }
    }
}
</style>
