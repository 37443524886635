<template>
  <div id="map" ref="map" :class="[platform=='mobile'?'mobile':'pc']">
  </div>
</template>

<script>
import BMap from 'BMap'
import logoloc from '@/assets/logo-locaiton.png'
import logoNav from '@/assets/Icon/nav.png'
import platform from "@/api/platform";

export default {
    name: 'Map',
    data () {
        return {
            imgUrl: logoloc,
            imgURLMobile:logoNav,
            platform:''
        }
    },
    methods: {
        createMap () {
            if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
                let map = new BMap.Map('map')
                map.centerAndZoom(new BMap.Point(119.294677, 26.082133), 18)
                map.setCurrentCity('福州')
                map.enableScrollWheelZoom(true)
                let markers = { content: '黄金集团3号楼2层', title: '东际网丰', position: { lat: 26.08304, lng: 119.294629 } }
                let point = new BMap.Point(markers.position.lng, markers.position.lat)
                let marker = new BMap.Marker(point, {
                    icon: new BMap.Icon(this.imgURLMobile, new BMap.Size(68, 68), {
                        imageOffset: new BMap.Size(0, 5)
                    })
                })
                let label = new BMap.Label(markers.title, { offset: new BMap.Size(-25, -25) })
                marker.setLabel(label)
                map.addOverlay(marker)
                label.setStyle({ // 设置label的样式
                    color: '#555555',
                    fontSize: '16px',
                    borderRadius: '16px',
                    border:'1px solid #ffffff',
                    width:'90px',
                    height:'38px',
                    textAlign:'center',
                    lineHeight:'34px',
                })
                // map.setMapStyleV2({     
                //     styleId: '4613ce60ab6bb809c5f208dee7e86492'
                // });
            }else{
                let map = new BMap.Map('map')
                map.centerAndZoom(new BMap.Point(119.294377, 26.083133), 17)
                map.setCurrentCity('福州')
                map.enableScrollWheelZoom(true)
                let markers = { content: '黄金集团3号楼2层', title: '东际网丰', position: { lat: 26.08304, lng: 119.294629 } }
                let point = new BMap.Point(markers.position.lng, markers.position.lat)
                let marker = new BMap.Marker(point, {
                    icon: new BMap.Icon(this.imgUrl, new BMap.Size(30, 46), {
                        imageOffset: new BMap.Size(0, 5)
                    })
                })
                let label = new BMap.Label(markers.title, { offset: new BMap.Size(-10, -15) })
                marker.setLabel(label)
                map.addOverlay(marker)
                label.setStyle({ // 设置label的样式
                    color: '#555555',
                    fontSize: '12px',
                    border: '1px solid #57D8FF',
                    borderRadius: '3px'
                })
            }
        },
    },
    mounted () {
        this.platform=platform
        if (this.platform=='mobile'){
            this.$refs.map.style.height=window.screen.availHeight*0.91+'px'
        }
        this.createMap()
    }
}
</script>
<style scoped lang="scss">
.pc{
    &#map{
        width: 550px;
        height: 400px;
        border:10px solid #ffffff;
        position: relative;
        .hide{
            display: none;
        }
    }
}
.mobile{
    &#map{
        width: 100%;
        height: 700px;
        position: relative;
        .unwanted{
            display: none;
        }
    }
}
</style>
